<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="addData" v-right-code="'Ruleofwhole:Update'">新增</el-button> </div>
                <div class="h-b"><el-button type="text" size="small" v-right-code="'Ruleofwhole:Update'" @click="saveData">保存</el-button> </div>
            </div>
        </div>
        <div class="form-list">
            <el-form ref="_dataInfoForm" :model="dataSource" :rules="dataCheckRule">
                <el-form-item>
                        <el-col :span="4" class="form-title"><span style="color:red;">*</span>规则名称：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="RuleName">
                           <el-input v-model="dataSource.RuleName" maxlength="50" placeholder="规则名称"></el-input>
                        </el-form-item>
                    </el-col>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>优先级：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="Priority">
                                     <el-input type="text" maxlength="5" v-model.number="dataSource.Priority"></el-input>
                                      
                                </el-form-item>
                            </el-col>
                </el-form-item>
                <el-form-item>
                     
                    <el-col :span="4" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
                    <el-col :span="8">
                       <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
                    </el-col>
                </el-form-item>
                 <el-form-item>
                    <el-divider content-position="left">单据审核</el-divider>
                </el-form-item>
                <el-form-item>
                    <el-col :span="6" class="from-item-left">
                        <el-form-item>
                            <el-checkbox v-model="dataSource.DisplayEnableAutomaticAuditing" @change="changeExamine">
                                启用自动审核
                            </el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16" v-if="dataSource.DisplayEnableAutomaticAuditing">
                        <el-radio-group v-model="dataSource.EnableAutomaticAuditing">
                            <el-radio :label="1">全部自动审核（所有订单默认审核通过）</el-radio>
                            <el-radio :label="2">按照审单规则审核（按照审单策略进行单据审核控制）</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-divider content-position="left">履约调度</el-divider>
                </el-form-item>
                <el-form-item>
                    <el-col :span="6" class="from-item-left">
                        <el-form-item>
                            <el-checkbox v-model="dataSource.DisplayOpenIntelligentScheduling" @change="changeDispatch">
                                启用自动履约调度
                            </el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16" v-if="dataSource.DisplayOpenIntelligentScheduling">
                        <el-radio-group v-model="dataSource.OpenIntelligentScheduling">
                            <el-radio :label="1">自动履约调度（按照业务策略自动履约调度）</el-radio>
                            <el-radio :label="2">人工履约调度（手动处理单据）</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-form-item>
               
                <el-form-item>
                    <el-divider content-position="left">调度频次</el-divider>
                </el-form-item>
                <el-form-item>
                    <el-col :span="4" class="form-title">审核下发：</el-col>
                    <el-col :span="5">
                        <el-form-item>
                            <el-select v-model="dataSource.ExamineInterval" :maxlength="200">
                                <el-option v-for="item in this.intervalList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" style="margin-left:5px">
                        <el-form-item>
                            <el-select v-model="dataSource.ExamineAccording" :maxlength="200">
                                <el-option v-for="item in this.accordingList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" style="margin-left:5px">
                        <el-form-item>
                            <el-input v-model="dataSource.ExamineValue" oninput="this.value=this.value.replace(/[^0-9]+/,'');" :maxlength="10"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2" style="margin-left:5px">{{this.examineAccording}} 下发一次</el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="4" class="form-title">单据调度：</el-col>
                    <el-col :span="5">
                        <el-form-item>
                            <el-select v-model="dataSource.DocumentInterval" :maxlength="200">
                                <el-option v-for="item in this.intervalList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" style="margin-left:5px">
                        <el-form-item>
                            <el-select v-model="dataSource.DocumentAccording" :maxlength="200">
                                <el-option v-for="item in this.accordingList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" style="margin-left:5px">
                        <el-form-item>
                            <el-input v-model="dataSource.DocumentValue" oninput="this.value=this.value.replace(/[^0-9]+/,'');" :maxlength="10"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2" style="margin-left:5px">{{this.documentAccording}} 调度一次</el-col>
                </el-form-item>
            </el-form>
        </div>
        <DialogEx :options="projectOptions" title="选择项目" @onOk="onProjectOk">
            <Project ref="project" :config="projectConfig" @onSelectedRow="onProjectSelectedRow" @onDBClick="onProjectOk"></Project>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        data() {
            var _this=this;
            var checkRuleName = function (rule, value, callback) {
                if (!value) return callback(new Error('请输入规则名称'));
                if(_this.Utils.isEmpty(value)) return callback(new Error("请输入规则名称"));
                callback();
            };

            var checkPriority= function (rule, value, callback) {
            if (value == null || value.length == 0)
                return callback(new Error("优先级不能为空"));
            if(value>32767)
                return callback(new Error("优先级数值过大"));
            callback();
            };

            return {
                multipleSelection: [],
                isDisabled: false,
                intervalList: [
                    { label: '每间隔', value: 1 },
                    { label: '无', value: 2 },
                ],
                projectList:[],
                accordingList: [
                    { label: '按小时', value: 1 },
                    { label: '按分钟', value: 2 },
                ],
                dataCheckRule: {
                    RuleName: [{ validator: checkRuleName, trigger: 'blur' }],
                    Priority:[{ validator: checkPriority, trigger: "blur" }]
                },
                examineAccording: '',
                documentAccording: '',
                //选择项目
                projectConfig: {
                    isDetailDisplay: true,
                },
                projectOptions: {
                    visible: false,
                    size: 'small'
                },
                currentProjectSelectedRow: {}
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource: {}
        },
        mounted() {
            this.Event.$on("clearEditDataForm", () => this.resetForm);
            this.initProjectList();
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.isNotNull(this.dataSource.Id);
                    this.examineAccording = this.dataSource.ExamineAccording == 1 ? '小时' : '分钟';
                    this.documentAccording = this.dataSource.DocumentAccording == 1 ? '小时' : '分钟';
                },
                deep: true
            }
        },
        methods: {
            initProjectList: function () {
            var _this = this;
                return;
            _this.$ajax.send(
                "omsapi/ruletoorderdisassembly/getprojectlist", "get", {}, (data) => {
                    if (data.IsSuccess) {
                        _this.projectList = data.Result;
                    }
                }
            );
        },
            //选择项目
            onProjectSelectedRow: function (row) {
                this.currentProjectSelectedRow = row;
            },
            onProjectOk: function (isDbClick) {
                var row = this.currentProjectSelectedRow;
                if (row instanceof Object && this.Utils.isNotNull(row.Id)) {
                    this.dataSource.ProjectCode = row.ProjectCode;
                    this.dataSource.ProjectName = row.ProjectName;
                }
                if (isDbClick) {
                    this.projectOptions.visible = false;
                }
            },
            addData() {
                this.Event.$emit("onAddData");
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_dataInfoForm'].resetFields();//清空表单
            },
            saveData() {
                var _this = this;
                _this.$refs["_dataInfoForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                         if(_this.dataSource.CustomerCode==null || _this.dataSource.CustomerCode.length==0)
                        {
                            _this.Utils.messageBox("未选择货主名称", "error");
                            return ;
                        }
                        
                        var routeName = _this.Utils.isNull(_this.dataSource.Id) ? "create" : "update";
                        _this.$ajax.send("omsapi/ruleOfWhole/" + routeName, "post", _this.dataSource, (data) => {
                            _this.Utils.isNull(_this.dataSource.Id) && (_this.dataSource.Id = data.Result);
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadDataPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            changeDispatch(val) {
                if (!val) {
                    this.dataSource.OpenIntelligentScheduling = null
                }else{
                    if(this.dataSource.OpenIntelligentScheduling==null || this.dataSource.OpenIntelligentScheduling==undefined)
                    {
                        this.dataSource.OpenIntelligentScheduling=1;
                    }
                }
            },
            projectChange()
            {
                var ProjectName="";
                var list=this.projectList;
                if(list!=null && list.length>0)
                {
                    for(var a=0;a<list.length;a++)
                    {
                        if(list[a].value==this.dataSource.ProjectCode)
                        {
                            ProjectName=list[a].label;
                            break;
                        }
                    }

                }
                this.dataSource.ProjectName=ProjectName;
            },
            changeExamine(val) {
                if (!val) {
                    this.dataSource.EnableAutomaticAuditing = null
                }else{
                    if(this.dataSource.EnableAutomaticAuditing==null || this.dataSource.EnableAutomaticAuditing==undefined)
                    {
                        this.dataSource.EnableAutomaticAuditing=1;
                    }
                }
            },
            customerChange(){
                //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
                //this.productConfig.CustomerCode=this.dataSource.CustomerCode
            },
        },
        components: {
            "Project": resolve => { require(['@/components/business/selector/projectselector.vue'], resolve) },
        }
    }

</script>

<style>
</style>